/* You can add global styles to this file, and also import other style files */

.error-snackbar {
    background: rgb(161, 12, 12);
  }

.mat-tooltip {
    font-size: 14px;
}

.mat-tooltip.md-11 {
  font-size: 11px;
}

.material-icons.md-36 {
  font-size: 36px;
  height: 36px;
  width: 36px;
}

.material-icons.md-16 {
  font-size: 16px;
  height: 16px;
  width: 16px;
}

.material-icons.md-14 {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.material-icons.md-round-14 {
  font-size: 14px;
}

.material-icons.color-grey {
  color: grey;
}

.material-icons.color-red {
  color: darkred;
}

.mat-button.md-nav {
  height: 70px;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.title-right {
  flex: 1 1 auto;
  text-align: right;
}

.title-center {
  flex: 1 1 auto;
  text-align: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.form-field-hidden {
  width: 100%;
  overflow: hidden;
}

.form-field-expanded {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.form-field {
  width: 100%;
}

.form-container > * {
  width: 85%;
}

.form-container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-container-row > * {
  width: 85%;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-form-field {
  padding-top: 4px;
  padding-bottom: 4px;
}

.mat-drawer-content{
  padding: 0px 20px 0px 20px;
}

.mat-tab-body-content {
  padding-left: 10px;
  padding-right: 10px;
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.mat-dialog-actions {
  justify-content: flex-end;
  width: 85%;
  margin: auto;
  margin-bottom: 10px !important;
}

.mat-stroked-button{
  font-weight: bold !important;
}

.mat-dialog-content {
  margin: 0px !important;
  padding: 0px !important;
}

.mat-dialog-container {
  padding: 0px !important;
}

.mat-dialog-title {
  padding: 24px;
}

html, body {
    height: 100%;
}

body {
    margin: 0;
}
